<template>
  <v-row>
    <v-col cols="12">
      <!--
      <v-row class="pa-4" justify="space-between">
        <v-col cols="6" md="2">
          <v-card
            color="secondary"
            class="d-flex flex-column align-items-center"
            elevation="4"
          >
            <v-card-title>Ödenen Taksit</v-card-title>

            <v-card-title class="my-0 pt-0">
              {{ payedInstallmentCount }}
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="6" md="2">
          <v-card
            color="secondary"
            class="d-flex flex-column align-items-center"
            elevation="4"
          >
            <v-card-title>Kalan Taksit</v-card-title>

            <v-card-title class="my-0 pt-0">
              {{ remainingInstallmentCount }}
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" md="2">
          <v-card
            color="secondary"
            class="d-flex flex-column align-items-center"
            elevation="4"
          >
            <v-card-title>Toplam Ödenen</v-card-title>

            <v-card-title class="my-0 pt-0">
              {{ formatPrice(totalPayedPrice) }}
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" md="2">
          <v-card
            color="secondary"
            class="d-flex flex-column align-items-center"
            elevation="4"
          >
            <v-card-title>Toplam Ödenmeyen</v-card-title>

            <v-card-title class="my-0 pt-0">
              {{ formatPrice(remainingTotalPriceForPay) }}
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" md="2">
          <v-card
            color="secondary"
            class="d-flex flex-column align-items-center"
            elevation="4"
          >
            <v-card-title>Toplam Tutar</v-card-title>

            <v-card-title class="my-0 pt-0">
              {{ formatPrice(totalPrice) }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      -->
      <v-row>
        <v-col cols="12">
          <v-simple-table height="auto" class="border">
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">Taksit</th>
                  <th class="text-left">Tutar</th>
                  <th class="text-left">Son Ödeme Tarihi</th>
                  <!--<th class="text-left">Durum</th>-->
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item /*index*/ in installments" :key="item.name">
                  <!-- <td style="width: 10px; padding-right: 0" v-if="!viewer">
                  <v-checkbox
                    v-if="item.status !== 'paid'"
                    class="mt-0 pt-0"
                    hide-details="auto"
                    :value="item.id"
                    v-model="selectedInstallments"
                  />
                </td> -->
                  <td style="padding-right: 0">
                    <b>{{ item.installment_number }}</b>
                  </td>
                  <td style="padding-right: 0">
                    {{ formatPrice(item.installment_amount) }}
                  </td>
                  <td style="padding-right: 0">
                    {{ formatDate(item.due_date) }}
                  </td>
                  <!--
                <td style="padding-right: 0">
                  <v-chip
                    pill
                    :color="
                      getInstallmentStatus(item.payment_on, item.due_date)
                        .color
                    "
                    x-small
                  >
                    {{
                      getInstallmentStatus(item.payment_on, item.due_date)
                        .text
                    }}</v-chip
                  >
                </td>
                -->
                  <td v-if="!viewer">
                    <!--
                    <PaymentModal>
                      <v-btn
                        elevation="0"
                        color="primary"
                        small
                        block
                        v-if="!item.payment_on && getFirstUnpaid === index + 1"
                      >
                        Öde
                      </v-btn>
                    </PaymentModal>
                    -->
                  </td>
                  <td
                    v-if="viewer"
                    class="d-flex flex-wrap align-items-center"
                    style="width: 30rem; gap: 1.5rem"
                  >
                    <v-btn
                      v-if="!item.payment_on"
                      @click="
                        payInstallment(
                          item.payment_plan_id,
                          item.installment_number,
                          item.installment_amount,
                          new Date()
                        )
                      "
                      color="success"
                      small
                    >
                      Ödemeyi İşle
                    </v-btn>
                    <v-btn
                      v-else
                      @click="
                        payInstallment(
                          item.payment_plan_id,
                          item.installment_number,
                          null,
                          null
                        )
                      "
                      color="error"
                      small
                    >
                      Ödemeyi İptal Et
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <!-- <v-row class="d-flex justify-content-between mt-6 p-2" v-if="!viewer">
        <span
          >Toplam Ödeme:
          <b>{{ selectedTotalPrice | VMask(currencyMask) }}</b></span
        >
        <PaymentModal>
          <v-btn :disabled="selectedInstallments.length == 0" color="primary">
            Toplu Ödeme
          </v-btn>
        </PaymentModal>
      </v-row> -->
    </v-col>
  </v-row>
</template>

<script>
/*import PaymentModal from "@/view/content/payment/PaymentModal";*/
import { formatPrice } from "@/core/helper.js";

export default {
  name: "PaymentViewer",
  /*components: { PaymentModal },*/
  props: {
    installments: {
      type: Array,
      required: true,
    },
    totalPrice: {
      type: Number,
      required: true,
    },
    viewer: Boolean,
  },
  data() {
    return {
      selectedInstallments: [],
      payment_amount: null,
    };
  },
  methods: {
    formatPrice,
    formatDate(date) {
      return this.$moment(date).format("DD/MM/YYYY");
    },
    getInstallmentStatus(payment_on, due_date) {
      const paymentDate = payment_on
        ? this.$moment(payment_on).format("DD/MM/YYYY")
        : null;
      // if paymentDate is null and due date is less than today
      if (!paymentDate && this.$moment(due_date).isBefore(this.$moment())) {
        return { text: "Gecikti", color: "error" };
      }
      // if paymentDate is null and due date is greater than today
      if (!paymentDate && this.$moment(due_date).isAfter(this.$moment())) {
        return { text: "Ödenmedi", color: "warning" };
      }

      return { text: "Ödendi", color: "success" };
    },
    payInstallment(
      paymentPlanId,
      installment_number,
      payment_amount,
      payment_on
    ) {
      this.$apiService
        .put("user-payment-plans/" + paymentPlanId, {
          installment_number,
          payment_amount,
          payment_on,
        })
        .then(({ data }) => {
          this.$toast.success("Ödeme başarıyla işlendi!", {
            position: "bottom-right",
            duration: 2000,
          });
          this.$emit("update:installment", data);
        })
        .catch((err) => {
          this.$toast.error(err[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        });
    },
  },
  computed: {
    getFirstUnpaid() {
      let firstUnpaid = this.installments.find((item) => !item.payment_on);
      return firstUnpaid ? firstUnpaid.installment_number : null;
    },
    selectedTotalPrice() {
      return this.installments.reduce((total, item) => {
        if (this.selectedInstallments.includes(item.id)) {
          total += item.amount;
        }
        return total;
      }, 0);
    },
    remainingTotalPriceForPay() {
      return Object.values(this.installments).reduce((total, item) => {
        if (!item.payment_on) {
          total += parseFloat(item.installment_amount);
        }
        return total;
      }, 0);
    },
    totalPayedPrice() {
      return this.totalPrice - this.remainingTotalPriceForPay;
    },
    remainingInstallmentCount() {
      return Object.values(this.installments).filter((item) => !item.payment_on)
        .length;
    },
    payedInstallmentCount() {
      return Object.values(this.installments).filter((item) => item.payment_on)
        .length;
    },
  },
};
</script>
