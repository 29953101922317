<template>
  <v-col :cols="colSize">
    <v-card elevation="12" class="rounded-xl rounded-tr-0">
      <v-row no-gutters>
        <v-col cols="12">
          <div class="d-flex justify-content-end align-items-center">
            <div class="status">{{ lead?.status_name }}</div>
          </div>
        </v-col>
      </v-row>
      <v-card-text class="text font-weight-medium">
        <v-icon> mdi-account </v-icon>
        {{ lead?.name }}
      </v-card-text>
      <v-card-text class="text font-weight-medium">
        <v-icon> mdi-phone </v-icon>
        {{ formatPhoneInput(lead?.phone) }}
      </v-card-text>
      <v-card-text class="text font-weight-medium">
        <v-icon> mdi-email </v-icon>
        {{ lead?.email }}
      </v-card-text>
      <v-card-text class="text font-weight-medium">
        <v-icon> mdi-source-merge </v-icon>
        {{ lead?.channel?.name }}
      </v-card-text>
      <v-card-text class="text font-weight-medium">
        <v-icon> mdi-face-agent </v-icon>
        {{ lead.agent ? lead?.agent?.name : "Atanmamış" }}
      </v-card-text>
      <v-card-actions class="justify-content-center">
        <router-link
          :to="{
            name: 'lead-show',
            params: { id: lead.id },
          }"
        >
          <v-btn
            color="primary"
            elevation="0"
            class="text-h7 rounded-xl button"
            dark
          >
            Bilgileri Gör
          </v-btn>
        </router-link>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import { formatPhoneInput } from "../../../../core/helper";

export default {
  name: "ApplicationCard",
  methods: { formatPhoneInput },
  props: {
    lead: {
      type: Object,
      default: () => ({}),
    },
    colSize: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style scoped>
.text {
  font-size: 16px;
  padding: 11px;
}

.button {
  background-color: #3f51b5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.status {
  font-size: 14px;
  padding: 11px;
  color: white;
  background-color: #11e83c;
  border-radius: 5px;
  text-align: center;
}
</style>
