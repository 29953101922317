<template>
  <fragment>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-text-field
          v-model="user.socials.instagram"
          hide-details="auto"
          label="Instagram"
        >
          <template #prepend>
            <v-icon>mdi-instagram</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="user.socials.facebook"
          hide-details="auto"
          label="Facebook"
        >
          <template #prepend>
            <v-icon>mdi-facebook</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="user.socials.linkedin"
          hide-details="auto"
          label="Linkedin"
        >
          <template #prepend>
            <v-icon>mdi-linkedin</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="user.socials.twitter"
          hide-details="auto"
          label="Twitter"
        >
          <template #prepend>
            <v-icon>mdi-twitter</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="user.socials.youtube"
          hide-details="auto"
          label="Youtube"
        >
          <template #prepend>
            <v-icon>mdi-youtube</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="user.socials.facebook_messenger"
          hide-details="auto"
          label="Facebook Messenger"
        >
          <template #prepend>
            <v-icon>mdi-facebook-messenger</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
export default {
  name: "Socials",
  props: {
    user: {
      type: Object,
    },
  },
  model: {
    prop: "user",
    event: "update:user",
  },
  watch: {
    user: {
      handler: function (val) {
        this.$emit("update:user", val);
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
