<template>
  <v-card flat class="pa-5">
    <div class="d-flex justify-content-between align-items-center">
      <h2 v-if="!hideTitle">Leadler</h2>
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'lead-create', query: { editable: true } }">
          <v-btn v-if="!hideNewLeadButton" elevation="0" color="primary">
            Lead Ekle
          </v-btn>
        </router-link>
      </div>
    </div>
    <v-row v-if="!loading && leads.length > 0" class="cards">
      <ApplicationCard
        v-for="lead in leads"
        :key="lead.id"
        :lead="lead"
        :colSize="colSize"
      />
    </v-row>
    <div
      v-else-if="!loading && leads.length === 0"
      style="height: 250px"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <h3 class="mt-5">Kullanıcıya ait lead bilgisi bulunamadı.</h3>
    </div>
    <div
      v-else
      style="height: 250px"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        :size="70"
        width="7"
      ></v-progress-circular>
      <h3 class="mt-5">Lütfen bekleyiniz...</h3>
    </div>
  </v-card>
</template>

<script>
import ApplicationCard from "@/view/content/components/applications/ApplicationCard";
import AxiosInstance from "@/core/services/axios.service";

export default {
  name: "ApplicationList",
  components: {
    ApplicationCard,
  },
  props: {
    user_id: {
      type: Number,
      default: null,
    },
    hideNewLeadButton: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    colSize: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      leads: [],
      loading: true,
    };
  },
  mounted() {
    this.getUserLeads();
  },
  methods: {
    getUserLeads() {
      AxiosInstance.get("/users/leads", {
        params: {
          user_id: this.user_id,
        },
      })
        .then((response) => {
          this.leads = response.data;
        })
        .catch(() => {
          this.$toast.error("Bir hata oluştu lütfen sayfayı yenileyiniz.", {
            position: "bottom-right",
            duration: 2000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.cards {
  margin-top: 20px;
}
</style>
