<template>
  <v-card>
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title><b>Dosya Yükle</b></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeDocument">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <Document
        :modelValue="document"
        @update:modelValue="(doc) => (document = doc)"
        @update:modelValueName="(val) => changeDocumentName(val)"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" :disabled="disabled" @click="closeDocument">
        İptal
      </v-btn>
      <v-btn color="primary" :disabled="disabled" @click="saveDocument">
        Kaydet
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import DocumentService from "@/core/services/api/documents.service";
import Document from "./Document.vue";

export default defineComponent({
  components: {
    Document,
  },
  props: {
    user_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      document: {},
      disabled: false,
    };
  },
  methods: {
    closeDocument() {
      this.document = {};
      this.disabled = false;
      this.$emit("closeDialog");
    },
    changeDocumentName(val) {
      this.document = new File([this.document], val, {
        type: this.document.type,
      });
    },
    saveDocument() {
      this.disabled = true;
      const userId = this.user_id || this.currentUser.id;
      try {
        DocumentService.uploadFile(this.document, {
          user_id: userId,
        }).then(() => {
          this.$toast.success("Dosya başarıyla yüklendi.", {
            position: "bottom-right",
            duration: 2000,
          });
          this.closeDocument();
        });
      } catch (error) {
        if (error[0].detail) {
          return this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
        this.$toast.error("Dosya yuklenirken bir hata olustu. ", {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
});
</script>
