var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{staticClass:"border",attrs:{"height":"auto"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Taksit")]),_c('th',{staticClass:"text-left"},[_vm._v("Tutar")]),_c('th',{staticClass:"text-left"},[_vm._v("Son Ödeme Tarihi")]),_c('th',{staticClass:"text-left"})])]),_c('tbody',_vm._l((_vm.installments),function(item /*index*/){return _c('tr',{key:item.name},[_c('td',{staticStyle:{"padding-right":"0"}},[_c('b',[_vm._v(_vm._s(item.installment_number))])]),_c('td',{staticStyle:{"padding-right":"0"}},[_vm._v(" "+_vm._s(_vm.formatPrice(item.installment_amount))+" ")]),_c('td',{staticStyle:{"padding-right":"0"}},[_vm._v(" "+_vm._s(_vm.formatDate(item.due_date))+" ")]),(!_vm.viewer)?_c('td'):_vm._e(),(_vm.viewer)?_c('td',{staticClass:"d-flex flex-wrap align-items-center",staticStyle:{"width":"30rem","gap":"1.5rem"}},[(!item.payment_on)?_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":function($event){_vm.payInstallment(
                        item.payment_plan_id,
                        item.installment_number,
                        item.installment_amount,
                        new Date()
                      )}}},[_vm._v(" Ödemeyi İşle ")]):_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.payInstallment(
                        item.payment_plan_id,
                        item.installment_number,
                        null,
                        null
                      )}}},[_vm._v(" Ödemeyi İptal Et ")])],1):_vm._e()])}),0)]},proxy:true}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }