<template>
  <Card
    :title="title"
    :tabs="items"
    :tab-disabled="isChanged"
    :disabled="isDisabled"
    @change="handleTabChange"
  >
    <template #close>
      <a @click.prevent="$router.go(-1)">
        <v-btn icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </a>
    </template>
    <template #actions>
      <v-btn @click="editMode = !editMode">
        <span v-if="editMode">Görüntüle</span>
        <span v-if="!editMode">Düzenle</span>
      </v-btn>
    </template>
    <template #item.informations>
      <EditProfile :user_id="Number($route.params.id)" />
    </template>
    <template #item.applications>
      <ApplicationList :user_id="Number($route.params.id)" />
    </template>
    <template #item.notes>
      <Notes
        :noteable_type="'App\\Models\\User'"
        :noteable_id="Number($route.params.id)"
      />
    </template>
    <template #item.offers>
      <Offers :user_id="Number($route.params.id)" />
    </template>
    <template #item.payment_plan>
      <PaymentPlan :user_id="Number($route.params.id)" />
    </template>
    <template #item.documents>
      <Documents :disabled="!editMode" :user_id="Number($route.params.id)" />
    </template>
  </Card>
</template>

<script>
import Notes from "@/view/content/notes/Notes.vue";
import Documents from "@/view/content/documents/Documents.vue";
import Offers from "@/view/content/offers/Offers.vue";
import Card from "@/view/content/components/Card";
import PaymentPlan from "@/view/content/users/PaymentPlan";
import ApplicationList from "@/view/pages/applications/ApplicationList";
import EditProfile from "@/view/pages/user/profile/EditProfile";

export default {
  data() {
    return {
      editMode: false,
      modal: false,
      birthDateMenu: false,
      date: null,
      tab: null,
      manualEnable: false,
      items: [
        { value: "informations", text: "Bilgiler" },
        { value: "applications", text: "Leadler" },
        { value: "notes", text: "Notlar" },
        { value: "offers", text: "Teklifler" },
        { value: "payment_plan", text: "Ödeme Planı" },
        { value: "documents", text: "Belgeler" },
      ],
      isChanged: false,
    };
  },
  created() {
    this.$route.query.editable
      ? (this.editMode = true)
      : (this.editMode = false);
  },
  methods: {
    handleTabChange(tab) {
      if (this.editMode) return;

      if (tab == 4 || tab == 3) {
        this.manualEnable = true;
      } else {
        this.manualEnable = false;
      }
    },
  },
  computed: {
    hasId() {
      return this.$route.params.id;
    },
    title() {
      return this.$route.params.id ? "Kullanıcı Düzenle" : "Kullanıcı Ekle";
    },
    isDisabled() {
      if (this.manualEnable) {
        return false;
      }
      return !this.editMode;
    },
  },
  components: {
    PaymentPlan,
    Card,
    Notes,
    Documents,
    Offers,
    ApplicationList,
    EditProfile,
  },
};
</script>

<style></style>
