<template>
  <v-stepper v-if="!loading" non-linear vertical v-model="activeFormIndex">
    <div v-for="(form, index) in activeForms" :key="index">
      <v-stepper-step
        editable
        :complete="activeFormIndex > index"
        :edit-icon="activeFormIndex === index + 1 ? 'mdi-pencil' : 'mdi-check'"
        :color="activeFormIndex === index + 1 ? 'primary' : 'grey lighten-2'"
        :step="index + 1"
        >{{ form.name }}
      </v-stepper-step>
      <v-stepper-content :step="index + 1">
        <component
          :isFormValid="isFormValid"
          :is="form.component"
          v-model="user"
        />
        <div class="mt-3"></div>
      </v-stepper-content>
      <v-spacer></v-spacer>
    </div>
    <v-row justify="end">
      <v-btn color="primary" style="right: 25px" @click="save"
        >Bilgileri Güncelle
      </v-btn>
    </v-row>
  </v-stepper>
  <div
    v-else
    style="height: 250px"
    class="d-flex flex-column justify-content-center align-items-center"
  >
    <v-progress-circular
      indeterminate
      color="primary"
      :size="70"
      width="7"
    ></v-progress-circular>
    <h3 class="mt-5">Lütfen bekleyiniz...</h3>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { UPDATE_USER, GET_USER } from "@/core/services/store/users.module";

import Information from "@/view/content/users/informations/Information";
import Address from "@/view/content/users/informations/Address";
import Socials from "@/view/content/users/informations/Socials";
import WorkInformation from "@/view/content/users/informations/WorkInformation";
import ParentInformation from "@/view/content/users/informations/ParentInformation";
import ParentAddress from "@/view/content/users/informations/ParentAddress";
import Preferences from "@/view/content/users/informations/Preferences";

import { checkUserAgeUnder18 } from "@/core/helper.js";

export default {
  name: "EditProfile",
  components: {
    Socials,
    Address,
    Information,
    WorkInformation,
    ParentInformation,
    ParentAddress,
    Preferences,
  },
  props: {
    user_id: Number,
  },
  created() {
    const interval = setInterval(() => {
      if (this.user_id || this.currentUser.id) {
        this.getUserAndPrepareData();
        clearInterval(interval);
      }
    }, 250);
  },
  data() {
    return {
      isFormValid: true,
      loading: true,
      user: {},
      activeFormIndex: 1,
      forms: [
        {
          name: "Kişisel Bilgiler",
          component: "Information",
          isOptional: false,
        },
        {
          name: "Adres Bilgileri",
          component: "Address",
          isOptional: false,
        },
        {
          name: "Aile Bilgileri",
          component: "ParentInformation",
          isOptional: false,
        },
        {
          name: "Aile Adres Bilgileri",
          component: "ParentAddress",
          isOptional: false,
        },
        {
          name: "Çalışma Durumu",
          component: "WorkInformation",
          isOptional: false,
        },
        {
          name: "Sosyal Medya Bilgileri",
          component: "Socials",
          isOptional: true,
        },
        {
          name: "Tercihler",
          component: "Preferences",
          isOptional: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions([UPDATE_USER, GET_USER]),
    checkUserAgeUnder18,
    changeForm(index) {
      this.activeFormIndex = index;
    },
    save() {
      this.updateUser(this.user)
        .then(() => {
          this.$toast.success("Bilgiler başarıyla güncellendi.", {
            position: "bottom-right",
            timeout: 2000,
          });
        })
        .catch((error) => {
          let message =
            error[0].status == 500
              ? "Güncelleme işlemine devam etmek istiyorsanız kullanıcıya ait ilgili bütün alanların doldurulması gerekmektedir."
              : error[0].detail;
          this.$toast.error(message, {
            position: "bottom-right",
            duration: 2000,
          });
        });
    },
    getUserAndPrepareData() {
      this.getUser(this.user_id || this.currentUser.id)
        .then((user) => {
          this.user = {
            ...user,
            address: user.address ?? {},
            parent_information: user.parent_information
              ? {
                  ...user.parent_information,
                  email: user.parent_information.email.email,
                }
              : {},
            work_information: user.work_information ?? {},
            socials: user.socials ?? {},
            preferences: user.preferences ?? {},
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    activeForm() {
      return this.forms[this.activeFormIndex].component;
    },
    activeForms() {
      if (!this.checkUserAgeUnder18(this.user.birth_date))
        return this.forms.filter(
          (form) =>
            form.name !== "Aile Bilgileri" &&
            form.name !== "Aile Adres Bilgileri"
        );
      return this.forms;
    },
  },
};
</script>
