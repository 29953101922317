<template>
  <fragment>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-select
          :items="classes"
          v-model="user.preferences.third_base_class_choice"
          label="3. Aşama Tercihin Ne Olurdu?"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="user.preferences.choice_reason"
          hide-details="auto"
          label="Neden Bizi Tercih Ettin?"
          rows="3"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="user.preferences.register_reason"
          label="Neden Yazılımcı Olmak İstiyorsun?"
          hide-details="auto"
          rows="3"
        />
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
export default {
  name: "Preferences",
  props: {
    user: {
      type: Object,
    },
  },
  model: {
    prop: "user",
    event: "update:user",
  },
  data() {
    return {
      classes: [
        "İleri Full Stack Geliştirme",
        "Yapay Zeka",
        "Oyun Geliştirme",
        "Mobil Geliştirme",
        "Siber Güvenlik",
      ],
    };
  },
  watch: {
    user: {
      handler: function (val) {
        this.$emit("update:user", val);
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
