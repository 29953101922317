<template>
  <v-card flat class="pa-5">
    <v-data-table :headers="headers" :items="payments">
      <template #top>
        <div class="d-flex justify-content-between align-items-center">
          <h2>Ödeme Planları</h2>
        </div>
      </template>
      <template #item.actions="{ item }">
        <v-dialog v-model="dialog">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="warning" v-bind="attrs" v-on="on"> mdi-eye </v-icon>
          </template>
          <v-card>
            <v-card-text>
              <PaymentViewer
                v-if="dialog"
                :installments="item.installments"
                :totalPrice="item.totalPrice"
                @update:installment="updateInstallment($event)"
                :viewer="true"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.totalPrice="{ item }">
        {{ formatPrice(item.totalPrice) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import PaymentViewer from "@/view/content/payment/PaymentViewer";
import { formatPrice } from "@/core/helper.js";

export default {
  name: "PaymentPlan",
  components: { PaymentViewer },
  props: {
    user_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: true,
      payments: [],
      headers: [
        { text: "Taksit Sayisi", value: "installment_count", align: "left" },
        {
          text: "Ödenen Taksit Sayısı",
          value: "payed_installment_count",
          align: "left",
        },
        {
          text: "Taksit Tutarı",
          value: "installment_amount",
          align: "left",
        },
        {
          text: "Toplam Tutar",
          value: "totalPrice",
          align: "left",
        },
        {
          text: "Bir Sonraki Ödeme Tarihi",
          value: "nextPaymentDueDate",
          align: "left",
        },
        {
          text: "Ödeme Durumu",
          value: "isCompleted",
          align: "left",
        },
        {
          text: "",
          value: "actions",
          align: "left",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    formatPrice,
    getUserPaymentPlans(user_id) {
      return new Promise((resolve, reject) => {
        this.$apiService
          .get(`/user-payment-plans/${user_id}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTotalPrice(data) {
      return Object.values(data).reduce((acc, curr) => {
        return acc + parseFloat(curr.installment_amount);
      }, 0);
    },
    updateInstallment(data) {
      const installment = Object.values(this.payments)
        .find((item) => item.payment_plan_id === data.payment_plan_id)
        .installments.find(
          (item) => item.installment_number === data.installment_number
        );
      installment.payment_on = this.$moment(data.payment_on).format(
        "YYYY-MM-DD"
      );
      return installment;
    },
  },
  created() {
    this.getUserPaymentPlans(this.user_id)
      .then((offers) => {
        this.payments = Object.values(offers).reduce((acc, curr) => {
          const temp = {
            payment_plan_id: curr[0].payment_plan_id,
            installment_amount: formatPrice(curr[0].installment_amount),
            installment_count: Object.values(curr).length,
            payed_installment_count: Object.values(curr).filter(
              (item) => item.payment_on !== null
            ).length,
            installments: Object.values(curr),
            isCompleted: Object.values(curr).find(
              (item) => item.payment_on === null
            )
              ? "Devam Ediyor"
              : "Tamamlandı",
            nextPaymentDueDate: Object.values(curr).find((item) => {
              return item.payment_on === null;
            })
              ? this.$moment(
                  Object.values(curr).find((item) => item.payment_on === null)
                    .due_date
                ).format("DD/MM/YYYY")
              : "Tüm ödemeler tamamlandı.",
            totalPrice: this.getTotalPrice(curr),
          };

          acc.push(temp);
          return acc;
        }, []);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped></style>
