import { render, staticRenderFns } from "./Preferences.vue?vue&type=template&id=00c7ec04&scoped=true"
import script from "./Preferences.vue?vue&type=script&lang=js"
export * from "./Preferences.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00c7ec04",
  null
  
)

export default component.exports