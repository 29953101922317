<template>
  <div>
    <v-toolbar
      v-if="isStudent"
      color="#1a1a27"
      class="text-white rounded-0"
      height="80px"
    >
      <img v-if="asideEnabled" :src="siteLogo()" alt="Logo" width="75" />
      <v-spacer v-if="asideEnabled"></v-spacer>
      <v-toolbar-title class="ml-3">
        <h2 class="mb-0">Belgelerim</h2>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <KTTopbar></KTTopbar>
    </v-toolbar>
    <v-row v-if="!deletable" class="mt-0 mx-0">
      <v-row v-if="!loading && documents.length > 0" class="mx-4 mt-5 mt-i-5">
        <v-col
          cols="12"
          md="3"
          v-for="document in mappedDocuments"
          :key="document.id"
        >
          <v-card
            @click="
              $vuetify.breakpoint.smAndDown
                ? downloadDocument(document)
                : showDocument(document)
            "
            color="#1a1a27"
            class="pa-5"
            rounded="xl"
            dark
            elevation="4"
          >
            <div class="d-flex justify-content-between">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click.stop="showDocument(document)"
                    dark
                    v-if="$vuetify.breakpoint.mdAndUp"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>Görüntüle</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click.stop="downloadDocument(document)"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="ml-5"
                  >
                    mdi-download
                  </v-icon>
                </template>
                <span>Indir</span>
              </v-tooltip>
            </div>
            <div class="d-flex justify-content-center">
              <v-icon class="mb-5" color="#ef5350" size="82"
                >mdi-file-document</v-icon
              >
            </div>
            <v-card-text>
              <v-row class="d-flex justify-content-center text-body-1">
                <v-col cols="4">Dosya İsimi: </v-col>
                <v-col cols="8">
                  <span style="color: #ef5350">
                    {{ document.file_name }}
                  </span>
                </v-col>
                <v-col cols="4">Dosya Boyutu: </v-col>
                <v-col cols="8"
                  ><span style="color: #ef5350">
                    {{ document.size | convertHumanReadable }}
                  </span>
                </v-col>
                <v-col cols="4">Dosya Tipi: </v-col>
                <v-col cols="8"
                  ><span style="color: #ef5350">
                    {{ document.type }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div
        v-else-if="loading"
        style="height: 250px; width: 100%"
        class="d-flex flex-column justify-content-end align-items-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          :size="70"
          width="7"
        ></v-progress-circular>
        <h3 class="mt-5">Lütfen bekleyiniz...</h3>
      </div>
      <v-row v-else>
        <v-sheet
          elevation="0"
          max-width="600"
          rounded="lg"
          width="100%"
          class="pa-4 text-center mx-auto mt-10"
        >
          <v-icon class="mb-5" color="info" size="112">
            mdi-alert-circle
          </v-icon>

          <h2 class="text-h5 mb-6">Döküman Bulunmamaktadır.</h2>
        </v-sheet>
      </v-row>
    </v-row>
    <v-card v-else flat class="pa-5">
      <v-data-table
        v-model="selectedDocuments"
        :headers="filesHeaders"
        :items="mappedDocuments"
        :search="search"
        :loading="loading"
      >
        <template #top>
          <div class="d-flex justify-content-between align-items-center">
            <h2>Belgeler</h2>
            <div class="d-flex align-items-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="getDocuments()"
                    color="cursor-pointer"
                    class="mr-4"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-reload
                  </v-icon>
                </template>
                <span>Yenile</span>
              </v-tooltip>
              <DocumentModal :user_id="user_id">
                <v-btn elevation="0" color="primary" v-bind="attrs" v-on="on">
                  <v-icon small>mdi-upload</v-icon>
                  Dosya Yükle
                </v-btn>
              </DocumentModal>
            </div>
          </div>
        </template>
        <template #item.size="{ item }">
          {{ item.size | convertHumanReadable }}
        </template>
        <template #item.file_name="{ item }">
          <input
            v-model="item.name"
            v-if="editableDocumentId === item.id"
            :style="{
              color: 'primary',
            }"
            :ref="`input-${item.id}`"
            @blur="updateDocument(item)"
            @keyup.enter="updateDocument(item)"
          />
          <span v-else>{{ item.file_name }}</span>
        </template>
        <template #item.actions="{ item }">
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon elevation="0" v-on="on" v-bind="attrs">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <template v-slot:default>
              <v-list>
                <v-list-item
                  v-for="(element, index) in actionMenuElements"
                  :key="index"
                >
                  <v-btn
                    v-show="element.active"
                    text
                    width="100%"
                    @click="element.action(item)"
                    :class="element.class"
                    elevation="0"
                    >{{ element.text }}</v-btn
                  >
                </v-list-item>
              </v-list>
            </template>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { humanReadableFileSize } from "vuetify/lib/util/helpers";
import DocumentModal from "@/view/content/documents/DocumentsModal.vue";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import objectPath from "object-path";
import { mapGetters } from "vuex";

import DocumentService from "@/core/services/api/documents.service.js";

import { defineComponent } from "vue";
export default defineComponent({
  props: {
    documentableType: String,
    documentableId: String,
    selectable: {
      type: Boolean,
      default: true,
    },
    singleSelect: {
      type: Boolean,
      default: true,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    user_id: {
      type: Number,
      default: null,
    },
    disabled: Boolean,
  },
  filters: {
    convertHumanReadable(value) {
      return humanReadableFileSize(Number(value), true);
    },
  },
  data() {
    return {
      filesHeaders: [
        {
          value: "actions",
          text: "Eylemler",
          sortable: false,
          width: "100px",
        },
        { value: "file_name", text: "İsim" },
        { value: "size", text: "Boyut" },
        { value: "type", text: "Tip" },
        { value: "updated_at", text: "Son Değiştirme" },
      ],
      actionMenuElements: [
        {
          text: "Görüntüle",
          active: true,
          action: (item) => {
            this.showDocument(item);
          },
        },
        {
          text: "Dosyayı İndir",
          active: true,
          action: (item) => {
            this.downloadDocument(item);
          },
        },
        {
          text: "Yeniden Adlandır",
          active: true,
          action: (item) => {
            this.renameDocument(item);
          },
        },
        {
          text: "Sil",
          class: "text-danger",
          active: this.deletable,
          action: (item) => {
            this.showDeleteDocumentModal(item);
          },
        },
      ],
      loading: true,
      documents: [],
      editableDocumentId: null,
      editDocumentMode: false,
    };
  },
  async mounted() {
    await this.getDocuments();
  },
  methods: {
    renameDocument(doc) {
      this.editableDocumentId = doc.id;
      this.editDocumentMode = true;
      this.$nextTick(() => {
        this.$refs[`input-${doc.id}`].focus();
      });
    },
    showDeleteDocumentModal(doc) {
      const params = {
        title: "Belge Silinecek",
        text: "Yaptığınız işlem seçtiğiniz belgeyi geri alınamaz bir şekilde silecektir. Bunu onaylıyor musunuz?",
        onConfirm: () => {
          return this.acceptDelete(doc);
        },
      };
      this.$approvalModal.show(params);
    },
    async acceptDelete(doc) {
      try {
        await DocumentService.deleteFile(doc.id);
        this.$toast.success("Belge başarıyla silindi.", {
          position: "bottom-right",
          duration: 2000,
        });
        this.getDocuments();
      } catch (error) {
        this.$toast.error("Belge silinirken bir hata oluştu.", {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    async getDocuments() {
      const user_id = this.$route.params.id || this.currentUser.id;
      try {
        this.documents = await DocumentService.getDocuments({ user_id });
        this.loading = false;
      } catch {
        this.$toast.error(
          "Belgeler alınırken bir hata oluştu. Lütfen yenile butonuna tıklayınız ve ya sayfayı yenileyiniz.",
          {
            position: "bottom-right",
            duration: 2000,
          }
        );
      }
    },
    showDocument(doc) {
      const params = {
        fileURL: doc.path,
        mimeType: doc.mime_type,
        fileName: doc.name,
      };
      this.$documentReader.show(params);
    },
    async downloadDocument(doc) {
      try {
        const blob = await DocumentService.getFile(doc.path);

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const url = URL.createObjectURL(blob);
        const link = this.createDownloadLink(url, doc.name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        this.$toast.error("Belge indirilirken bir hata oluştu.", {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    async updateDocument(doc) {
      if (this.editDocumentMode) {
        try {
          await DocumentService.updateFileName(doc.id, doc.name);
          this.$toast.success("Belge başarıyla yeniden adlandırıldı.", {
            position: "bottom-right",
            duration: 2000,
          });

          this.getDocuments();
        } catch (error) {
          this.$toast.error("Belge yeniden adlandırılırken bir hata oluştu.", {
            position: "bottom-right",
            duration: 2000,
          });
        }
        this.editableDocumentId = null;
        this.editDocumentMode = false;
      } else return;
    },
    createDownloadLink(url, filename) {
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      return link;
    },
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    },
  },

  computed: {
    ...mapGetters(["currentUser", "layoutConfig"]),
    mappedDocuments() {
      return this.documents.map((document) => {
        return {
          ...document,
          updated_at: new Date(document.updated_at).toLocaleDateString(),
          type: document.mime_type.split("/")[1],
          file_name: document.name.includes(".")
            ? document.name.split(".")[0]
            : document.name,
          path: DocumentService.createPreviewLinkOfDocument(document),
        };
      });
    },
    isStudent() {
      if (this.currentUser?.roles?.length === 0) return true;
      else if (
        this.currentUser?.roles?.length > 0 &&
        this.currentUser?.roles[0]?.name === "student"
      ) {
        return true;
      } else return false;
    },
    asideEnabled() {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  components: { DocumentModal, KTTopbar },
});
</script>

<style>
input:focus {
  color: rgb(33, 33, 226);
}
</style>
