<template>
  <v-dialog :disabled="disabled" v-model="dialog" persistent max-width="600">
    <template #activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot />
      </div>
    </template>

    <DocumentEdit :user_id="user_id" @closeDialog="closeDialog" />
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import DocumentEdit from "./DocumentEdit.vue";

export default defineComponent({
  components: {
    DocumentEdit,
  },
  props: {
    user_id: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
  },
});
</script>
