<template>
  <div class="file--upload-section cursor-pointer">
    <div
      @drop.prevent="onDrop($event)"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      @click="openFileDialog"
      :class="{ 'grey lighten-2': dragover }"
    >
      <div class="file--placeholder">
        <h1>Dosyayı Seç</h1>
        <input
          type="file"
          ref="fileInput"
          style="display: none"
          @change="onSelect($event)"
        />
        <span class="text-bold"> {{ document.name }}</span>
        <v-icon
          :class="[dragover ? 'mt-2, mb-6' : 'mt-2']"
          x-large
          class="mb-2"
        >
          mdi-cloud-upload</v-icon
        >
        <span>Sürükle & Bırak</span>
      </div>
    </div>
    <v-text-field
      v-model="documentName"
      label="Dosya Adı"
      class="mt-4"
      v-if="document"
      hide-details="auto"
      single-line
    ></v-text-field>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Document",
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      dragover: false,
    };
  },
  methods: {
    onDrop(e) {
      this.dragover = false;
      if (this.document) this.document = {};
      this.document = e.dataTransfer.files[0];
    },
    onSelect(e) {
      this.dragover = false;
      if (this.document) this.document = {};
      this.document = e.target.files[0];
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
  },
  computed: {
    document: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    documentName: {
      get() {
        return this.modelValue.name;
      },
      set(value) {
        this.$emit("update:modelValueName", value);
      },
    },
  },
});
</script>

<style>
.file--upload-section {
  padding: 20px;
  min-height: max-content;
}
.file--placeholder {
  color: #a1a1a1e0;
  width: 100%;
  height: 18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 3px dashed #a1a1a15b;
  border-radius: 12px;
}
</style>
